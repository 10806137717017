.background {
    background-color: #5E69EA;
    
    color: white;
    font-size: 3rem;
    
    display: flex;
    justify-content: center;
    padding: .5em;

    --dot-timing: 4s;
}

.message{
    position: relative;
    transition: all .38s ease-in-out;
}

.message.up{
    top: -200px;
}

.message.left{
    left: -100%;
}

.message.right{
    left: 100%;
}

.message.center{
    left: 0%;
}

.effect-af{
    color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .effect-af {
    animation: af infinite 4s linear;
  }

  .dot1{
    animation: dot1 infinite var(--dot-timing) step-start;
  }
  .dot2{
    animation: dot2 infinite var(--dot-timing) step-start;
  }
  .dot3{
    animation: dot3 infinite var(--dot-timing) step-start;
  }
}

@keyframes dot1 {
    from, 25%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes dot2 {
    from, 50%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes dot3 {
    from, 75%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes slide{
    from{
        left: 100%
    }
    2%,98%{
        left: 0%
    }
    to{
        left: -100%
    }
}

@keyframes af {
  from, to {
    text-shadow: 0 0 12px white;
  }
  25%{
    text-shadow: 0 0 6px white;
  }
  50%{
    text-shadow: 0 0 9px white;
  }
  75%{
    text-shadow: 0 0 0px white;
  }
}
